<template>
	<div class="add-node-form form-closed" :class="{isImported : this.getCurrentNode.isImported ? true : false}" id="add-node-form">
		<form id="add-node-form" novalidate>
			<div class="col left">
				<div class="controls two-column">
					<ul>
						<li>
							<a class="btn-save save" @click.prevent="saveContentTree"><i class="fa-solid fa-floppy-disk"></i>Save</a>
						</li>
						<li>
							<a class="btn-close close" href="#" @click.prevent="confirmCloseAddNode()"><i class="fa-sharp fa-solid fa-xmark"></i>Close</a>
						</li>
					</ul>
				</div>
				<!-- end controls -->
				<div class="fieldset-links">
					<p class="subject-title">{{ fieldValue("title", "title") }}</p>
					<ul>
						<li v-for="(item, index) in filteredFormItems" :key="item" :class="{active: (index === 0 && !activeId) || item.id === activeId}">
							<a href="#" :data-id="item.id" @click.prevent="dataId(item.id)">{{ item.itemName }}</a>
							<ValidateIcon></ValidateIcon>
						</li>
					</ul>
				</div>
				<!-- end fieldset-links-->
				<div class="controls align-bottom">
					<p class="required-field">Required field*</p>
					<ul>
						<li>
							<a class="btn-close btn-close--full-width remove" @click.prevent="removeNode"><i class="fa-solid fa-delete-right"></i>Delete</a>
						</li>
					</ul>
				</div>
				<!-- bottom-controls -->
			</div>

			<div class="col right">
				<div class="fieldset-wrapper">
					<fieldset class="fieldset active" data-id="title">
						<HideFromUser fieldId="title-hide-from-user" :key="1" :checked="fieldValue('title', 'hideFromUser')" :value="'title'" @input="hideUserValue"></HideFromUser>
						<div class="subject-title form-item">
							<TextField :key="'nodeTitle'" :fieldId="'nodeTitle'" :fieldPlaceholder="'Node Title'" :isRequired="true" :valueProp="fieldValue('title', 'title')" @fieldUpdated="(value) => updateFieldValue($event, 'title', 'title', value)"></TextField>
							<button class="add-node-form-ai-btn ai-generate-icon" @click.prevent="this.$store.commit('client/setAIPopUpFields', {prop: 'modals/titleField', val: true})" ><i class="fa-solid fa-wand-magic-sparkles"></i></button>
						</div>
					</fieldset>

					<fieldset class="fieldset" data-id="details">
						<HideFromUser fieldId="details-hide-from-user" :key="2" :checked="fieldValue('details', 'hideFromUser')" :value="'details'" @input="hideUserValue"></HideFromUser>
						<div class="details form-item">
							<Textarea :fieldId="'details'" :fieldPlaceholder="'Details'" :isRequired="false" :valueProp="fieldValue('details', 'details')" @fieldUpdated="(value) => updateFieldValue($event, 'details', 'details', value)"></Textarea>
							<button class="add-node-form-ai-btn ai-generate-icon" @click.prevent="this.$store.commit('client/setAIPopUpFields', {prop: 'modals/detailsField', val: true})" ><i class="fa-solid fa-wand-magic-sparkles"></i></button>
						</div>
					</fieldset>

					<fieldset v-if="!isCategory" class="fieldset" data-id="assign">
						<HideFromUser fieldId="assign-hide-from-user" :key="3" :checked="fieldValue('assign', 'hideFromUser')" :value="'assign'" @input="hideUserValue"></HideFromUser>
						<div class="assign form-item">
							<div class="styled-select select" id="author">
								<VueNextSelect v-model="assigned" class="form-control" :model-value="assign" :placeholder="fieldValue('assign', 'assign') ? fieldValue('assign', 'assign').fullName : 'Select User'" :options="getClientUsers" label-by="fullName" close-on-select @selected="(payload) => updateFieldValue($event, 'assign', 'assign', payload)"></VueNextSelect>
								<!-- <div class="error error-message">This field is required.</div> -->
							</div>
							<!-- end styled-select -->
						</div>
					</fieldset>

					<fieldset v-if="!isCategory" class="fieldset" data-id="deadline">
						<HideFromUser fieldId="deadline-hide-from-user" :key="4" :checked="fieldValue('deadline', 'hideFromUser')" :value="'deadline'" @input="hideUserValue"></HideFromUser>
						<div class="deadline form-item datepicker">
							<Datepicker v-model="deadlineDefault" class="form-control" dark autoApply format="dd/MM/yyyy HH:mm"></Datepicker>
						</div>
					</fieldset>

					<fieldset v-if="!isCategory" class="fieldset" data-id="keywords">
						<HideFromUser fieldId="keywords-hide-from-user" :key="5" :checked="fieldValue('keyword', 'hideFromUser')" :value="'keyword'" @input="hideUserValue"></HideFromUser>
						<div class="keywords form-item">
							<TextField :fieldId="'keywords'" :fieldPlaceholder="'Focus Keyword'" :isRequired="true" :valueProp="fieldValue('keyword', 'keyword')" @fieldUpdated="(value) => updateFieldValue($event, 'keyword', 'keyword', value)"></TextField>
						</div>
						<div class="reach form-item">
							<label>Reach</label>
							<TextField :fieldId="'reach'" :fieldPlaceholder="'Reach'" :isRequired="false" :valueProp="fieldValue('keyword', 'reach')" @fieldUpdated="(value) => updateFieldValue($event, 'keyword', 'reach', value)"></TextField>
						</div>
						<button class="access-keyword-planner-btn" @click.prevent="onOpenKeywordPlanner">Keyword Planner <i class="fa-sharp fa-solid fa-chevron-right"></i></button>
					</fieldset>

					<fieldset v-if="!isCategory" class="fieldset" data-id="format" :class="{ 'blockAccessToFields': this.blockAccessToFields }">
						<HideFromUser fieldId="format-hide-from-user" :key="6" :checked="fieldValue('format', 'hideFromUser')" :value="'format'" @input="hideUserValue"></HideFromUser>
						<div class="format form-item styled-select select">
							<label>Format</label>
							<VueNextSelect
							v-model="format" 
							class="form-control select-format-field"
							:model-value="format" 
							:placeholder="fieldValue('format', 'format') ? fieldValue('format', 'format') : 'Select Format'" 
							:options="['Blog', 'Social']"
							close-on-select 
							@selected="(payload) => updateFieldValue($event, 'format', 'format', payload)"
							:isRequired="true"
							/>
						</div>
						<div class="format form-item styled-select select" v-if="getCurrentNode.fieldGroups.format.fields.format.value === 'Social'">
							<label>Platform Focus</label>
							<VueNextSelect
							v-model="focus" 
							class="form-control select-format-field"
							:model-value="focus" 
							:placeholder="fieldValue('format', 'focus') ? fieldValue('format', 'focus') : 'Select Platform Focus'" 
							:options="['Facebook', 'Instagram', 'Linkedin', 'X']"
							close-on-select 
							@selected="(payload) => updateFieldValue($event, 'format', 'focus', payload)"
							:isRequired="true"
							/>
						</div>
					</fieldset>

					<fieldset v-if="!isCategory" class="fieldset" data-id="intent">
						<HideFromUser fieldId="intent-hide-from-user" :key="7" :checked="fieldValue('intent', 'hideFromUser')" :value="'intent'" @input="hideUserValue"></HideFromUser>
						<div class="intent form-item">
							<TextField v-model="ranking" :fieldId="'ranking'" :fieldPlaceholder="'Intent'" :isRequired="true" :valueProp="fieldValue('intent', 'intent')" @fieldUpdated="(value) => updateFieldValue($event, 'intent', 'intent', value)"></TextField>
						</div>
					</fieldset>

					<fieldset v-if="!isCategory" class="fieldset" data-id="research">
						<HideFromUser fieldId="research-hide-from-user" :key="8" :checked="fieldValue('research', 'hideFromUser')" :value="'research'" @input="hideUserValue"></HideFromUser>
						<div class="research form-item">
							<Textarea v-model="research" :fieldId="'research'" :fieldPlaceholder="'Research'" :isRequired="false" :valueProp="fieldValue('research', 'research')" @fieldUpdated="(value) => updateFieldValue($event, 'research', 'research', value)"></Textarea>
						</div>
					</fieldset>

					<fieldset class="fieldset" data-id="status">
						<HideFromUser fieldId="status-hide-from-user" :key="9" :checked="fieldValue('status', 'hideFromUser')" :value="'status'" @input="hideUserValue"></HideFromUser>
						<div class="status form-item">
							<div class="styled-select select legend-select">
								<VueNextSelect v-model="foundStatus" class="form-control" :modelValue="status" :options="status" label-by="status" close-on-select id="selectedStatus" @selected="(payload) => updateFieldValue($event, 'status', 'status', payload.status)">
									<template #label="{selected}">
										<template v-if="selected">
											<span class="color" :style="'background-color:' + selected.color"></span><span class="title">{{ selected.status }}</span>
										</template>
										<template v-else> <span class="color" :style="'background-color: #FF50A1'"></span><span class="title">Title Created</span> </template>
									</template>
									<template #dropdown-item="{option}">
										<span class="color" :style="'background-color:' + option.color"></span><span class="title">{{ option.status }}</span>
									</template>
								</VueNextSelect>
							</div>
							<!-- end styled-select -->
						</div>
						<div v-if="!isCategory" class="notes form-item">
							<Textarea v-model="notes" :fieldId="'notes'" :fieldPlaceholder="'Notes'" :isRequired="false" :valueProp="fieldValue('status', 'notes')" @fieldUpdated="(value) => updateFieldValue($event, 'status', 'notes', value)"></Textarea>
						</div>
					</fieldset>

					<fieldset v-if="!isCategory" class="fieldset" data-id="content">
						<!-- <HideFromUser fieldId="research-hide-from-user" :key="10" :checked="fieldValue('content', 'hideFromUser')" :value="'content'" @input="hideUserValue"></HideFromUser> -->
					</fieldset>

					<Errors :errors="responseErrors" />
				</div>
				<!-- end fieldset-wrapper -->

				<!-- <div class="testing">
					<p>{{ hidefromuser }}</p>
					<p>Title: {{ fieldValue("title", "title") }}</p>
					<p>Details: {{ fieldValue("details", "details") }}</p>
					<p>Assign: {{ fieldValue("assign", "assign") }}</p>
					<p>Deadline: {{ fieldValue("deadline", "deadline") }}</p>
					<p>Keyword: {{ fieldValue("keyword", "keyword") }}</p>
					<p>Reach: {{ fieldValue("keyword", "reach") }}</p>
					<p>Format: {{ fieldValue("format", "format") }}</p>
					<p>Ranking: {{ fieldValue("intent", "intent") }}</p>
					<p>Research: {{ fieldValue("research", "research") }}</p>
					<p>Subject status: {{ fieldValue("status", "status") }}</p>
					<p>Notes: {{ fieldValue("status", "notes") }}</p>
				</div> -->
			</div>
		</form>
	</div>
	<!-- end add-subject-form -->
</template>

<script>
import VueNextSelect from "vue-next-select"
import "vue-next-select/dist/index.min.css"
import Datepicker from "vue3-date-time-picker"
import HideFromUser from "./inputs/HideFromUser.vue"
import TextField from "./inputs/TextField.vue"
import Textarea from "./inputs/Textarea.vue"
import Errors from "./validation/Errors.vue"
import ValidateIcon from "../forms/ValidateIcon.vue"
import useValidate from "@vuelidate/core"
//import {required} from "@vuelidate/validators"
import {mapGetters} from "vuex"
import {getClosest} from "../../assets/js/utils"

let main = null
let nodeForm = null
let nodeOverview = null

export default {
	name: "AddNodeForm",
	components: {
		Datepicker,
		VueNextSelect,
		HideFromUser,
		TextField,
		Textarea,
		ValidateIcon,
		Errors,
	},
	data() {
		return {
			v$: useValidate(),
			activeId: false,
			closed: true,
			formIsValid: true,
			responseErrors: [],
			blockAccessToFields: false,
			userHasChangedStatus: false,
			reloadComponent: 0,
			formItems: [
				{id: "title", itemName: "Title"},
				{id: "details", itemName: "Details"},
				{id: "assign", itemName: "Assign"},
				{id: "deadline", itemName: "Deadline"},
				{id: "keywords", itemName: "Keywords"},
				{id: "format", itemName: "Format"},
				{id: "intent", itemName: "Intent"},
				{id: "research", itemName: "Research"},
				{id: "status", itemName: "Status"},
				{id: "content", itemName: "Content"},
				{id: "social", itemName: "Social"},
			],
			status: [
				//{status: "Show All", color: ""},
				{status: "Title Created", color: "#FF50A1"},
				{status: "Assigned", color: "#2ABBEE"},
				{status: "Pending Review", color: "#FF8344"},
				{status: "Approved", color: "#44ffc4"},
				{status: "Rejected", color: "#FF0000"},
				{status: "Published", color: "#18B28C"},
				// {status: "Category", color: "#eee"},
			],
			hidefromuser: [],
			details: "",
			author: "",
			deadline: this.deadlineDefault,
			keywords: "",
			reach: "",
			format: "",
			focus: "",
			ranking: "",
			research: "",
			selectStatus: "",
			notes: "",
			assign: "",
			userShownCloseWarning: false,
			isViewingBlog: false,
		}
	},
	methods: {
		setParamEditorOpen(data){
			// Get the current URL
			let url = new URL(window.location.href);
			// Get the search parameters from the URL
			let searchParams = url.searchParams;
			// Set the new parameter
			searchParams.set('editor-open', data);
			// Update the URL with the new parameter
			window.history.pushState({}, '', url.toString());
		},
		onOpenKeywordPlanner(){
			this.$store.commit("client/setOpenKeywordPlanner", true);
		},
		validate() {
			this.v$.$validate()
		},
		async closeActions(){
			try{
				// console.log('CLOSE ACTIONS:::::::::::', this.getOldNodeId);
				// if(this.getOldNodeId){
				// 	this.$store.commit('client/setOldNodeId', this.getCurrentNode.id);
				// 	await this.$store.dispatch('client/checkIfOldNodeExsists');
				// }
				await this.$store.dispatch('client/checkIfOldNodeExsists');
				this.closeAddNode()
				this.userShownCloseWarning = false
				this.$store.commit("client/setOpenKeywordPlanner", false);
				this.$store.commit("client/setOpenBlogEditor", false);
				this.$store.commit("client/setOpenBlogEditorAddNodeForm", false);
				this.$store.commit('client/setSocial', {prop: 'currentPageSectionThatIsOpen', val: ''});
				if(this.getCurrentNode.id){
					document.querySelector('.node-overview').classList.add('overview-open');
				}
				this.$store.commit('client/setRemoveRowLoader', true);
			}catch(error){
				console.log('closeActions catch error', error);
			}
		},
		fieldStatus(validation) {
			return {
				error: validation.$error,
				valid: validation.$dirty,
			}
		},
		async confirmCloseAddNode() {
			if (this.userShownCloseWarning || !this.hasCurrentNodeBeenEdited) {
				await this.closeActions();
				this.responseErrors = [{$message: ""}];
				if(this.getCurrentNode.id){
					await this.$store.dispatch('client/updateNodeActionRecord', {nodeId: this.getCurrentNode.id, action: 'CLOSE'})
				}
				this.setParamEditorOpen(false);
			} else {
				alert("WARNING: Closing without saving will result in lost data!")
				this.userShownCloseWarning = true
			}
		},
		async saveContentTree() {
			try{
				const isNodePosting = await this.$store.dispatch("client/isNodePosting");
				if(isNodePosting.status === false){
					this.$store.commit('client/setHidePageLoader', false);

					this.v$.$validate()

					let labels = document.querySelectorAll(".fieldset-links ul li a")
					// Note: this previously had separate selectors for inputs/textareas and selects.
					// It's been joined as this seemed unnecessary, the code was duplicated exactly.
					let allElements = document.querySelectorAll(".form-control")
				
					allElements.forEach((el) => {
						var fieldset = getClosest(el, ".fieldset")
						if(fieldset){
							var data = fieldset.getAttribute("data-id")
							var elements = fieldset.querySelectorAll(".form-control")

							// Set it to valid by default
							labels.forEach((label) => {
								if (label.getAttribute("data-id") == data) {
									label.nextElementSibling.classList.remove("invalid")
									label.nextElementSibling.classList.add("valid")
								}
							})

							// And if any of the elements inside an individual fieldset are invalid set it to invalid
							elements.forEach((el) => {
								if (el.value == "" && el.classList.contains("error")) {
									labels.forEach((label) => {
										if (label.getAttribute("data-id") == data) {
											label.nextElementSibling.classList.add("invalid")
											label.nextElementSibling.classList.remove("valid")
										}
									})
								}
							})
						}
					})

					let selectHasError = false;
					if(this.getCurrentNode.level > 1 && this.getCurrentNode.fieldGroups.format.fields.format.value === ''){
						selectHasError = true;
						let formatElement = document.querySelector('[data-id="format"]');
						let validateElement = formatElement.parentElement.querySelector('.validate');
						validateElement.classList.remove('valid');
						validateElement.classList.add('invalid');
					}
					if(this.getCurrentNode.level > 1 && this.getCurrentNode.fieldGroups.title.fields.title.value === ''){
						selectHasError = true;
						let formatElement = document.querySelector('[data-id="title"]');
						let validateElement = formatElement.parentElement.querySelector('.validate');
						validateElement.classList.remove('valid');
						validateElement.classList.add('invalid');
					}
					if(this.getCurrentNode.level > 1 && this.getCurrentNode.fieldGroups.keyword.fields.keyword.value === ''){
						selectHasError = true;
						let formatElement = document.querySelector('[data-id="keywords"]');
						let validateElement = formatElement.parentElement.querySelector('.validate');
						validateElement.classList.remove('valid');
						validateElement.classList.add('invalid');
					}
					if(this.getCurrentNode.level > 1 && this.getCurrentNode.fieldGroups.intent.fields.intent.value === ''){
						selectHasError = true;
						let formatElement = document.querySelector('[data-id="intent"]');
						let validateElement = formatElement.parentElement.querySelector('.validate');
						validateElement.classList.remove('valid');
						validateElement.classList.add('invalid');
					}
					if(this.getCurrentNode.level > 1 && this.getCurrentNode.fieldGroups.format.fields.focus.value === '' && this.getCurrentNode.fieldGroups.format.fields.format.value === 'Social'){
						selectHasError = true;
						let formatElement = document.querySelector('[data-id="format"]');
						let validateElement = formatElement.parentElement.querySelector('.validate');
						validateElement.classList.remove('valid');
						validateElement.classList.add('invalid');
					}

					if (this.v$.$errors.length > 0 || selectHasError) {
						this.userShownCloseWarning = false;
						this.$store.commit('client/setHidePageLoader', true);
						return (this.responseErrors = [{$message: "Form has errors, please check and try again."}])
					} else {
						this.responseErrors = [{$message: ""}]
						this.$emit("treeSaved")
						console.log('this.$emit("treeSaved")');
						this.clearForm();
					}
					this.$store.commit("client/setOpenKeywordPlanner", false);
					this.$store.commit("client/setOpenBlogEditor", false);
					this.$store.commit("client/setOpenBlogEditorAddNodeForm", false);
					this.$store.commit('client/setSocial', {prop: 'currentPageSectionThatIsOpen', val: ''});
					this.closeAddNode()
					this.$store.commit('client/setIsCreatingNewNode', false);
				}else{
					alert(isNodePosting.error);
					this.$store.commit('client/setHidePageLoader', true);
				}
			}catch(error){
				console.log('openAddNode catch error', error);
			}
		},
		clearForm() {
			let labels = document.querySelectorAll(".fieldset-links ul li a")
			labels.forEach((label) => {
				label.nextElementSibling.classList.remove("valid")
				label.nextElementSibling.classList.remove("invalid")
			});
			this.format = '';
			this.focus = '';
		},
		async removeNode() {
			try{
				this.$store.commit('client/setHidePageLoader', false);
				const isNodePosting = await this.$store.dispatch("client/isNodePosting");
				if(isNodePosting.status === false){
					this.$emit("nodeRemoved")
					this.closeAddNode()
				}else{
					alert(isNodePosting.error);
					this.$store.commit('client/setHidePageLoader', true);
				}
			}catch(error){
				console.log('openAddNode catch error', error);
			}
		},
		updateFieldValue($event, fieldGroup, field, payload) {

			let value = $event ? $event.target.value : payload

			if(field === 'assign' && value !== '' && value !== null){
				this.$store.commit("client/updateNodeFieldValue", {fieldGroup: 'status', field: 'status', value: 'Assigned'});
			}
			
			if(field === 'status' && value === 'Approved' || field === 'status' && value === 'Rejected'){
				if(this.getClientData.mainContact){
					if(this.getAuthData.username !== this.getClientData.mainContact.username){
						alert('You must be the main contact to approve or reject');
					}else{
						console.log('1{fieldGroup, field, value}', [fieldGroup, field, value]);
						this.$store.commit("client/updateNodeFieldValue", {fieldGroup, field, value})
					}
				}else{
					alert('main contact not set error');
				}
			}else{
				console.log('2{fieldGroup, field, value}', [fieldGroup, field, value]);
				this.$store.commit("client/updateNodeFieldValue", {fieldGroup, field, value})
			}
		},
		updateBlogMediaTempStorage(payload) {
			this.$store.commit("client/setClientNodeMediaTempStorage", payload)
		},
		hideUserValue($event) {
			let checked = $event.target.checked
			let fieldGroup = $event.target.value
			let pseudoEvent = {target: {value: checked}}
			this.updateFieldValue(pseudoEvent, fieldGroup, "hideFromUser")
		},
		dataId: function (id) {
			if((id === 'social' || id === 'content') && this.getIsCreatingNewNodeToAddToDB){
				alert('You must save the node before using the editor');
			}else{
				this.activeId = id
				let formFieldsets = null
				formFieldsets = document.querySelectorAll("fieldset");

				formFieldsets.forEach((el) => {
					el.classList.remove("active") //remove class from active item
					if (el.getAttribute("data-id") === id) {
						el.classList.add("active") //add class to the item with the same data id
					}
					if(id === 'content'){
						this.$store.commit('client/setBlogTitle', this.fieldValue("title", "title"));
						this.$store.commit("client/setUserOpenedBlogOrSocialEditor", true);
						this.$store.commit("client/setOpenKeywordPlanner", false);
						this.$store.commit('client/setSocial', {prop: 'currentPageSectionThatIsOpen', val: ''});
						this.$store.commit("client/setOpenBlogEditorAddNodeForm", true);
						document.querySelector('.add-node-form.form-open').classList.add('add-node-form--enlarged');
						document.querySelector('.node-overview').classList.remove('overview-open');
						this.setParamEditorOpen(true);
						this.$store.commit('client/setShowEditor', false);
						console.log('should have generated content editor');
					}else if(id === 'social'){
						this.$store.commit("client/setUserOpenedBlogOrSocialEditor", true);
						this.$store.commit("client/setOpenKeywordPlanner", false);
						this.$store.commit("client/setOpenBlogEditor", false);
						this.$store.commit("client/setOpenBlogEditorAddNodeForm", false);
						this.$store.commit('client/setSocial', {prop: 'currentPageSectionThatIsOpen', val: 'add-node-form'});
						document.querySelector('.add-node-form.form-open').classList.add('add-node-form--enlarged');
						document.querySelector('.node-overview').classList.remove('overview-open');
						this.setParamEditorOpen(true);
						this.$store.commit('client/setShowEditor', false);
						console.log('should have generated social editor');
					}else{
						console.log('addnodeform else');
						nodeForm.classList.remove("form-closed")
						nodeForm.classList.add("form-open")
						this.$store.commit("client/setOpenKeywordPlanner", false);
						this.$store.commit("client/setOpenBlogEditor", false);
						this.$store.commit("client/setOpenBlogEditorAddNodeForm", false);
						this.$store.commit('client/setSocial', {prop: 'currentPageSectionThatIsOpen', val: ''});
						document.querySelector('main .flex-container').classList.remove('load-social');
						document.querySelector('.node-overview').classList.add('overview-open');
						if(document.querySelector('.add-node-form').classList.contains('add-node-form--enlarged')){
							document.querySelector('.add-node-form').classList.remove('add-node-form--enlarged')
						}
						this.setParamEditorOpen(false);
					}
				})
			}
		},
		closeAddNode() {
			this.$store.commit("client/setUserOpenedBlogOrSocialEditor", false);
			this.$store.commit("client/setOpenKeywordPlanner", false);
			this.$store.commit("client/setOpenBlogEditor", false);
			this.$store.commit("client/setOpenBlogEditorAddNodeForm", false);
			this.$store.commit('client/setSocial', {prop: 'currentPageSectionThatIsOpen', val: ''});
			document.querySelector('main .flex-container').classList.remove('load-social');
			if(this.getCurrentNode.id){
				document.querySelector('.node-overview').classList.add('overview-open');
			}
			if(document.querySelector('.add-node-form').classList.contains('add-node-form--enlarged')){
				document.querySelector('.add-node-form').classList.remove('add-node-form--enlarged')
			}

			if (nodeForm.classList.contains("form-open")) {
				main.classList.remove("node-form-open")
				main.classList.add("node-form-closed")
				nodeForm.classList.remove("form-open")
				nodeForm.classList.add("form-closed")
				this.clearForm()
			}
		},
		openNodeOverview() {
			// console.log("opening node overview")
			if (nodeOverview.classList.contains("overview-closed")) {
				main.classList.add("overview-open")
				main.classList.remove("overview-closed")
				nodeOverview.classList.add("overview-open")
				nodeOverview.classList.add("overview-closed")
			}
		},
		requiredCheck() {
			alert("this is required")
		},
		async socialInit(format){
			if(format === 'Social'){
				try{
					const response = await this.$store.dispatch('client/checkIfNodeExsists');
					if(response){
						await this.$store.dispatch('client/socialInit', this.getClientSlug);
						this.getSocial.post.hasbeenPublished ? this.blockAccessToFields = true : this.blockAccessToFields = false;
					}
				}catch(error){
					console.log(error);
				}
			}else{
				this.blockAccessToFields = false;
			}
        },
	},

	// Next - two way coupling for updating node field value
	computed: {
		filteredFormItems(){
			
			let arr = this.formItems

			if(this.isBlogArticle === false){
				arr = arr.filter(item => item.id !== 'content');
			}
			if(this.isSocial === false){
				arr = arr.filter(item => item.id !== 'social');
			}
			if(this.isCategory === true){
				arr = arr.filter(item => item.id === 'title');
			}

			return arr;
		},
		foundStatus() {
			return this.status.find((status) => status.status == this.fieldValue("status", "status"))
		},
		isCategory() {
			return  this.fieldValue("status", "status") === "Category"
		},
		isBlogArticle() {
			return  this.fieldValue("format", "format") === "Blog"
		},
		isSocial() {
			return  this.fieldValue("format", "format") === "Social"
		},
		assigned() {
			return this.getClientUsers.find((user) => user.username == this.fieldValue("assign", "assign").username)
		},
		legend() {
			return this.status.slice(1) //slice makes copy of status array instead of using original
		},
		fieldValue() {
			return (fieldGroup, fieldTitle) => {
				return this.$store.getters["client/getNodeFieldValue"](fieldGroup, fieldTitle)
			}
		},
		computedErrorMessage() {
			if(this.errorMessage){
				if (this.errorMessage.length > 0) {
					return this.errorMessage
				}
			}
			return ""
		},
		deadlineDefault: {
			get() {
				//your validation
				return this.fieldValue("deadline", "deadline")
			},
			set(val) {
				let pseudoEvent = {target: {value: val.toString()}}
				this.updateFieldValue(pseudoEvent, "deadline", "deadline")
				this.deadline = val
			},
		},
		...mapGetters("auth", ["getAuthData", "getAuthRoleName"]),
		...mapGetters("views", ["getSidebarOpenState"]),
		...mapGetters("client", ["getCurrentNode", "getOpenaddNodeClickTitle", "getShowEditor", "getOldNodeId", "getClientData", "getClientUsers", "hasCurrentNodeBeenEdited", "getAddNodeFormOpen", "getSocial", 'getIsCreatingNewNodeToAddToDB', 'getClientSlug']),
	},
	watch:{
		getShowEditor(newVal){
			if(newVal){
				nodeForm.classList.add("form-open");
				if(this.getCurrentNode.fieldGroups.format.fields.format.value === 'Blog'){
					this.dataId('content');
				}else{
					this.dataId('social');
				}
			}
		},
		getOpenaddNodeClickTitle(){
			this.dataId('title');
			this.$store.commit('client/setOpenaddNodeClickTitle', false);
		},
		getAddNodeFormOpen(newVal){
			console.log('getAddNodeFormOpen', newVal);
			const url = new URL(window.location.href);
			if(newVal === true && url.searchParams.get('editor-open') !== 'true'){
				//AND THAT USER HAS SELECTED DELETE
				console.log('getAddNOdenform is trueeeee')
				console.log('getAddNodeFormOpen WATCH', newVal);
				this.dataId('title');
				if(document.querySelector('.add-node-form').classList.contains('add-node-form--enlarged')){
					document.querySelector('.add-node-form').classList.remove('add-node-form--enlarged')
				}
				this.$store.commit('client/setAddNodeFormOpen', false);
			}else{
				console.log('getAddNOdenform is falseeeeeeee')
			}
		},
		'getCurrentNode.fieldGroups.format.fields.format.value': {
        deep: true,
            async handler(newValue) {
				await this.socialInit(newValue);
            }
        },
	},
	async mounted() {
		main = document.getElementById("main")
		nodeForm = document.getElementById("add-node-form")
		nodeOverview = document.getElementById("node-overview");
		console.log('add node form mounted')
		try{
			await this.socialInit(this.getCurrentNode.fieldGroups.format.fields.format.value);
			if(this.getCurrentNode.id){
				await this.$store.dispatch('client/updateNodeActionRecord', {nodeId: this.getCurrentNode.id, action: 'ENTER'})
			}
		}catch(error){
			console.log(error);
		}

	},
}
</script>
<style lang="scss">
@import "vue3-date-time-picker/src/Vue3DatePicker/style/main.scss";
@import "./../../assets/scss/components/addNodeForm.scss";
</style>
